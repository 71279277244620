import { ThemeProvider } from "styled-components";
import Layout from "../Layout";
import GlobalStyle from "../styled-components/utils/globalStyles";
import { theme } from "../styled-components/utils/theme";
import NextNprogress from "nextjs-progressbar";
import "react-toastify/dist/ReactToastify.css";

export default function App({ Component, pageProps }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout>
        <NextNprogress color="#e6be03" />
        <Component {...pageProps} />
      </Layout>
    </ThemeProvider>
  );
}
