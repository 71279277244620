import Link from "next/link";
import styled from "styled-components";

export const StyledLinks = styled.a`
  padding: 0.5rem;
  color: white;
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const HeaderLink = ({ href, name }) => (
  <Link href={href} passHref>
    <StyledLinks>{name}</StyledLinks>
  </Link>
);
export default HeaderLink;
