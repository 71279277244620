import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";

export const socialLinks = [
  {
    href: "https://www.linkedin.com/company/vidhigya-associates/",
    ico: FaLinkedin,
    label: "LinkedIn",
  },
  {
    href: "https://www.facebook.com/Valawoffices",
    ico: FaFacebook,
    label: "Facebook",
  },
  {
    href: "https://www.instagram.com/valawoffices/",
    ico: FaInstagram,
    label: "Instagram",
  },
];

export const footerData = [
  {
    title: "Practice Area",
    to: "/practice-area",
    links: [
      {
        title: "Real Estates",
        to: "/practice-area?id=real-estate",
      },
      {
        title: "Dispute Resolution",
        to: "/practice-area?id=dispute-resolution",
      },
      {
        title: "Bankruptcy And Insolvency",
        to: "/practice-area?id=bankruptcy-and-insolvency",
      },
      {
        title: "Intelectual Property",
        to: "/practice-area?id=intelectual-property",
      },
      {
        title: "Human Resource Law",
        to: "/practice-area?id=human-resource",
      },
      {
        title: "Contracts & Agreements",
        to: "/practice-area?id=contracts-and-agreements",
      },
    ],
  },
  {
    title: "Services",
    to: "/services",
    links: [
      {
        title: "General/Strategic Management",
        to: "/services?id=strategic-management",
      },
      {
        title: "Corporate Secretial Services",
        to: "/services?id=corporate-secretial-services",
      },
      {
        title: "Representation Services",
        to: "/services?id=representation-services",
      },
      {
        title: "Corporate Laws Advisory Services",
        to: "/services?id=corporate-laws-advisory-services",
      },
      {
        title: "Arbitration And Conciliation Services",
        to: "/services?id=arbitration-and-conciliation-services",
      },
      {
        title: "Finance Services",
        to: "/services?id=finance-services",
      },
    ],
  },
];
