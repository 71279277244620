import { useState } from "react";
import Footer from "./Footer";
import { Header } from "./Header";
import Sidebar from "./Sidebar";
import AcceptModal from "../components/Modal/AcceptModal";
import WhatsappIcon from "./WhatsappIcon";
import ContextWrapper from "../context";
import { LogoJsonLd, OrganizationJsonLd } from "next-seo";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <LogoJsonLd
        logo={
          process.env.NEXT_PUBLIC_BASE_URL +
          "/cropped-va-law-offices-logo-32x32.png"
        }
        url={process.env.NEXT_PUBLIC_BASE_URL}
      />
      <OrganizationJsonLd
        type="Corporation"
        id={process.env.NEXT_PUBLIC_BASE_URL}
        logo={
          process.env.NEXT_PUBLIC_BASE_URL +
          "/cropped-va-law-offices-logo-276x92.png"
        }
        legalName="Vidhigya Associates"
        name="Vidhigya Associates"
        address={{
          streetAddress: "F-01/ A-37, Haware Centurion Mall, Nerul",
          addressLocality: "Navi Mumbai",
          addressRegion: "MH",
          postalCode: "400706",
          addressCountry: "IN",
        }}
        contactPoint={[
          {
            telephone: "+91-91525-61219",
            contactType: "customer service",
            areaServed: "IN",
            availableLanguage: ["English", "Hindi", "Marathi", "Urdu"],
          },
          {
            telephone: "+91-97692-41709",
            contactType: "customer service",
            areaServed: "IN",
            availableLanguage: ["English", "Hindi", "Marathi", "Urdu"],
          },
        ]}
        sameAs={[
          "https://www.instagram.com/valawoffices/",
          "https://www.facebook.com/Valawoffices",
          "https://www.linkedin.com/company/vidhigya-associates/",
        ]}
        url={process.env.NEXT_PUBLIC_BASE_URL}
      />
      <ContextWrapper>
        <AcceptModal />
        <WhatsappIcon />
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Header toggle={toggle} />
        {children}
        <Footer />
      </ContextWrapper>
    </>
  );
};

export default Layout;
