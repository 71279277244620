import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context";
import {
  SubmitButton,
  ButtonBox,
  Modal,
  ModalContent,
  ModalTitle,
  ModalWrapper,
  RejectButton,
} from "./modalStyles";

const AcceptModal = () => {
  const [isActive, setIsActive] = useState(true);

  const { toggleOpen } = useContext(UserContext);

  useEffect(() => {
    document.body.classList.add("modal-open");
    const agreed = sessionStorage.getItem("Agreed");
    if (agreed) {
      toggleOpen();
      document.body.classList.remove("modal-open");
      setIsActive(false);
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    sessionStorage.setItem("Agreed", true);
    setIsActive(false);
    document.body.classList.remove("modal-open");
    window.scrollTo(0, 0);
    toggleOpen();
  };
  return (
    <ModalWrapper isActive={isActive}>
      <Modal onSubmit={submitHandler}>
        <ModalTitle>DISCLAIMER</ModalTitle>
        <ModalContent style={{ marginBottom: "15px" }}>
          As per the rules of the Bar Council of India, we are not permitted to
          solicit work and advertise. By clicking on the “I agree” below, the
          user acknowledges the following:
        </ModalContent>
        <ModalContent>
          1. There has been no advertisement, personal communication,
          solicitation, invitation or inducement of any sort whatsoever from us
          or any of our members to solicit any work through this website;
        </ModalContent>
        <ModalContent>
          2. The user wishes to gain more information about us for his/her own
          information and use;
        </ModalContent>
        <ModalContent>
          3. The information about us is provided to the user only on his/her
          specific request and any information obtained or materials downloaded
          from this website is completely at the user’s volition and any
          transmission, receipt or use of this site would not create any
          lawyer-client relationship.
        </ModalContent>
        <ModalContent style={{ marginTop: "15px" }}>
          The information provided under this website is solely available at
          your request for informational purposes only, should not be
          interpreted as soliciting or advertisement. We are not liable for any
          consequence of any action taken by the user relying on
          material/information provided under this website. In cases where the
          user has any legal issues, he/she in all cases must seek independent
          legal advice.
        </ModalContent>
        <ButtonBox>
          <SubmitButton type="submit">I Agree</SubmitButton>
          <RejectButton href="https://www.google.com">I Disagree</RejectButton>
        </ButtonBox>
      </Modal>
    </ModalWrapper>
  );
};

export default AcceptModal;
