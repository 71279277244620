import styled from "styled-components";

export const MainContainerWrap = styled.section`
  width: 100%;
  background: ${({ idx, altColor }) =>
    altColor && idx % 2 === 0 ? "whitesmoke" : "white"};
  min-height: ${({ half }) =>
    half ? "calc(50vh - 40px)" : "calc(100vh - 80px)"};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const MainContainer = styled.div`
  width: 100%;
  max-width: 1240px;
  display: grid;
  gap: 1rem;
  place-items: center;
  margin: 20px auto;
  padding: 0 1rem;
  ${({ isMax }) =>
    !isMax
      ? `
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin: 20px auto;
  @media screen and (max-width: 820px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding: 20px;
  }
  `
      : `
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  padding: 0 20px;
  @media screen and (max-width: 820px) {
  }
    `}
`;

export const ContainerInfo = styled.div`
  ${({ isCopyProtected }) =>
    isCopyProtected &&
    `
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  `}
  width: 100%;
  max-width: 100ch;
  display: flex;
  flex-flow: column;
  -webkit-box-pack: start;
  flex-wrap: wrap;

  p {
    text-align: justify;
  }

  li {
    margin-left: 20px;
  }

  * {
    margin-top: 20px;
  }
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #d6b000;
  font-weight: 400;
  width: 100%;
  white-space: wrap;
`;

export const SubTitle = styled.h3`
  margin-bottom: 1rem;
  font-family: "Times New Roman", Times, serif;
  font-size: ${({ size }) => (size === "small" ? "1.6rem" : "2.2rem")};
  line-height: 1 !important;
  font-weight: 600;
  color: ${({ color }) => (color ? `${color}` : "#1a1a1a")};
`;

export const Data = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
  color: #1a1a1a;
  max-width: 65ch;
  margin-bottom: 1rem;
  text-align: justify;
  white-space: initial;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 100%;
`;

export const ContainerImageDiv = styled.div`
  margin: 10px;
  width: fit-content;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    transform: scale(1.01);
  }
  @media screen and(max-width: 768px) {
    &:hover {
      transform: none;
    }
  }
`;
