import { footerData, socialLinks } from "./footerData";
import Link from "next/link";
import {
  FooterContainer,
  FooterLinkItems,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterWrap,
  FooterLink,
  SocialMediaWrap,
  SocialMedia,
  SocialIcons,
  WebsiteRights,
  SocialIconLink,
  FooterA,
  FooterP,
  FooterContactWrapper,
  Flex,
} from "./footerStyles";
import StyledHeaderLogo from "./StyledHeaderLogo";
import { SubTitle } from "../styled-components/utils/containerStyles";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterContactWrapper>
            <SubTitle color="white" size="small">
              Contact Us
            </SubTitle>
            <FooterA href="https://www.google.com/maps/place/V.+A+Law+Offices/@19.0247661,73.0196268,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7c3c488b5bd51:0x452d2a4a2f9375a0!8m2!3d19.0247661!4d73.0218208">
              Address: <br />
              F-01/ A-37, Haware Centurion Mall, <br />
              Nerul, NaviMumbai, 400706,
              <br />
              Maharashtra.
            </FooterA>
            <FooterA href="tel:919152561219">
              Office Contact: +91 91525 61219
            </FooterA>
            <FooterA href="tel:919769241709">
              Pankaj Rathod: +91 97692 41709
            </FooterA>
            <FooterA href="mailto:associates@valawoffices.com">
              Email: associates@valawoffices.com
            </FooterA>
            <FooterP>Mon to Fri: 10.00 AM – 7.00 PM</FooterP>
          </FooterContactWrapper>
          <FooterLinksWrapper>
            {footerData.map(({ title, to, links }, idx) => (
              <FooterLinkItems key={idx}>
                <Link href={to} passHref>
                  <SubTitle color="white" size="small">
                    {title}
                  </SubTitle>
                </Link>
                {links.map(({ title, to }) => (
                  <Link href={to} passHref key={to}>
                    <FooterLink>{title}</FooterLink>
                  </Link>
                ))}
              </FooterLinkItems>
            ))}
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <Flex justify={"center"} align={"center"} flow={"row"}>
              <StyledHeaderLogo margin={"20px"} footer={true} />
              <WebsiteRights>
                Vidhigya Associates &#169; {new Date().getFullYear()} All rights
                reserved
              </WebsiteRights>
            </Flex>
            <SocialIcons>
              {socialLinks.map((link, idx) => (
                <SocialIconLink
                  href={link.href}
                  target="_blank"
                  aria-label={link.label}
                  key={idx}
                >
                  <link.ico />
                </SocialIconLink>
              ))}
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
