import styled, { css } from "styled-components";

export const headerCss = css`
  margin-top: -120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  top: 120px;
  z-index: 10;
  width: 100%;
`;

export const HeaderWrap = styled.nav`
  transition: 0.8s all ease;
  background: ${({ scrollNav }) => (scrollNav ? "#000" : "transparent")};
  height: ${({ scrollNav }) => (scrollNav ? "80px" : "120px")};
  ${headerCss}

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const HeaderInner = styled.div`
  width: 100%;
  padding: 20px auto;
  max-width: 1240px;
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderLinksWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 970px) {
    display: none;
  }
`;

export const AppointmentButton = styled.a`
  padding: ${({ scrollNav }) => (scrollNav ? "1rem 1.5rem" : "1.5rem 2rem")};
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border-radius: 0.25rem;
  height: 40px;
  font-size: 1rem;
  border: transparent solid 1px;
  font-weight: 600;
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
  &:hover {
    border: ${({ theme }) => theme.colors.primary} solid 1px;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primary};
  }
  @media screen and (max-width: 1040px) {
    padding: 1.5rem 1.5rem;
  }

  @media screen and (max-width: 970px) {
    display: none;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 970px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(
      -100%,
      ${({ scrollNav }) => (scrollNav ? "45%" : "92%")}
    );
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;
