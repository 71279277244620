import styled, { css } from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #000;
`;

export const FooterWrap = styled.div`
  padding: 20px 20px 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 820px) {
    padding-top: 20px;
    flex-flow: column;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 16px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  width: 100%;
  margin: 32px;
`;

export const footerItemCss = css`
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 5px;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    transition: 0.3s ease-in-out;
  }
`;
export const FooterP = styled.p`
  ${footerItemCss}
`;

export const FooterA = styled.a`
  ${footerItemCss}
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;

  text-align: left;
  width: 100%;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.a`
  font-size: 16px;
  margin-bottom: 16px;
  color: inherit;
  text-transform: uppercase;
  font-weight: bold;
`;

export const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    transition: 0.3s ease-in-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  @media screen and (max-width: 820px) {
    flex-flow: column;
  }
`;

export const SocialMediaWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column-reverse;
  }
`;

export const SocialLogo = styled.a`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const WebsiteRights = styled.small`
  margin: 12px 0 0 12px;
  color: rgba(255, 255, 255, 0.5) !important;
  color: #fafafa;
  font-size: 0.9rem;
  margin-bottom: 16px;
  text-align: center;
  @media screen and (max-width: 820px) {
    margin: 10px 0 0 0;
    flex-direction: column-reverse;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 240px;

  @media screen and (max-width: 820px) {
    justify-content: space-between;
    margin: 0 0 10px 0;
  }
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
  margin-top: -15px;
  margin-right: 25px;
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media screen and (max-width: 820px) {
    margin-top: 0;
    margin-right: 0;
  }
`;
