import styled, { css } from "styled-components";

export const ModalWrapper = styled.div`
  position: fixed;
  display: ${({ isActive }) => (isActive ? "grid" : "none")};
  place-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  z-index: 101;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: saturate(180%) blur(25px);
  -webkit-backdrop-filter: blur(25px);
`;

export const Modal = styled.form`
  color: white;
  height: auto;
  max-width: 1124px;
  margin: auto;
  padding: 1.4rem 1.8rem;
  border-radius: 5px;
  position: relative;
  width: fit-content;
  background: #1a1a1a;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    height: 100%;
    margin-top: 0;
    padding: 1rem 1.4rem;
    border-radius: 0px;
    justify-content: flex-start;
    overflow: hidden scroll;
  }
`;

export const ModalTitle = styled.h2`
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ModalContent = styled.p`
  font: bold;
  font-size: 0.9rem;
  max-width: calc(100% - 20px);
`;

export const ButtonBox = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  width: 100%;
  margin: 0px auto;
`;

const buttonCSS = css`
  cursor: pointer;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  white-space: nowrap;
  border: none;
  width: 120px;
  padding: 10px;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background: white;
  }
`;

export const SubmitButton = styled.button`
  ${buttonCSS}
`;
export const RejectButton = styled.a`
  ${buttonCSS}
  text-align:center;
`;
