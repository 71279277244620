import {
  Icon,
  CloseIcon,
  SidebarContainer,
  SideBtnWrap,
  SidebarMenu,
  SidebarWrapper,
  SidebarLink,
  SidebarRoute,
} from "./SidebarElements";

export default function Sidebar({ isOpen, toggle }) {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon>
        <CloseIcon onClick={toggle} />
      </Icon>
      <SidebarWrapper onClick={toggle}>
        <SidebarMenu>
          <SidebarLink href="/" name="Home" />
          <SidebarLink href="/practice-area" name="Practice Area" />
          <SidebarLink href="/services" name="Services" />
          <SidebarLink href="/careers" name="Careers" />
          <SidebarLink href="/blogs" name="Blogs" />
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute href="/contact" toggle={toggle} name="CONTACT US" />
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
}
