import styled from "styled-components";
import Head from "next/head";

export const IconWrap = styled.div`
  z-index: 100;
  bottom: 30px;
  position: fixed;
  margin: 1em;
  right: 30px;

  @media screen and (max-width: 768px) {
    bottom: 20px;
    right: 20px;
    width: 50px;
  }
  .sbutton {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    color: white;
    margin: 20px auto 0;
    box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.18),
      0px 4px 12px -7px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
    position: relative;
  }
  .sbutton > i {
    font-size: 32px;
    line-height: 50px;
    transition: all 0.2s ease-in-out;
    transition-delay: 2s;
  }
  .sbutton:active,
  .sbutton:focus,
  .sbutton:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  }
  .sbutton:not(:last-child) {
    width: 50px;
    height: 50px;
    margin: 20px auto 0;
    opacity: 0;
  }
  .sbutton:not(:last-child) > i {
    font-size: 25px;
    line-height: 50px;
    transition: all 0.3s ease-in-out;
  }
  &:hover .sbutton:not(:last-child) {
    opacity: 1;
    width: 50px;
    height: 50px;
    margin: 15px auto 0;
  }
  .sbutton:nth-last-child(1) {
    -webkit-transition-delay: 25ms;
    transition-delay: 25ms;
  }
  .sbutton:not(:last-child):nth-last-child(2) {
    -webkit-transition-delay: 20ms;
    transition-delay: 20ms;
  }
  .sbutton:not(:last-child):nth-last-child(3) {
    -webkit-transition-delay: 40ms;
    transition-delay: 40ms;
  }
  .sbutton:not(:last-child):nth-last-child(4) {
    -webkit-transition-delay: 60ms;
    transition-delay: 60ms;
  }
  .sbutton:not(:last-child):nth-last-child(5) {
    -webkit-transition-delay: 80ms;
    transition-delay: 80ms;
  }
  .sbutton:not(:last-child):nth-last-child(6) {
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
  }
  .sbutton:not(:last-child):nth-last-child(7) {
    -webkit-transition-delay: 120ms;
    transition-delay: 120ms;
  }

  .sbutton.mainsbutton {
    background: #e6be03;
  }
  .sbutton.fb {
    background: #3f51b5;
  }
  .sbutton.whatsapp {
    background: #00e676;
  }
  .sbutton.instagram {
    background: #e1306c;
  }
  .sbutton.linkedin {
    background: #0077b5;
  }
  .sbutton.phone {
    background: white;
    color: #00e676;
  }
`;

export const StyledWhatsApp = styled.a`
  position: relative;
`;

const WhatsappIcon = () => {
  return (
    <>
      <Head>
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.css"
          rel="stylesheet"
        />
      </Head>
      <IconWrap>
        <a
          href="tel:919152561219"
          target="_blank"
          className="sbutton phone"
          rel="noreferrer"
        >
          <i className="fas fa-phone fa-flip-horizontal"></i>
        </a>

        <a
          href="https://api.whatsapp.com/send/?phone=919152561219"
          target="_blank"
          className="sbutton whatsapp"
          rel="noreferrer"
        >
          <i className="fab fa-whatsapp"></i>
        </a>

        <a
          href="https://www.linkedin.com/company/vidhigya-associates/"
          target="_blank"
          className="sbutton linkedin"
          rel="noreferrer"
        >
          <i className="fab fa-linkedin"></i>
        </a>

        <a
          href="https://www.instagram.com/valawoffices/"
          target="_blank"
          className="sbutton instagram"
          rel="noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>

        <a
          href="https://www.facebook.com/Valawoffices"
          rel="noreferrer"
          target="_blank"
          className="sbutton fb"
        >
          <i className="fab fa-facebook-f"></i>
        </a>

        <a target="_blank" className="sbutton mainsbutton">
          <i className="fas fa-address-book" style={{ fontSize: "25px" }}></i>
        </a>
      </IconWrap>
    </>
  );
};

export default WhatsappIcon;
