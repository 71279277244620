import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  #root{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: auto;
  }

  * {
    line-height: 1.5;
    font-family: "Times New Roman", Times, serif;  
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    font-size: 1.1rem;
  }

  u {
    text-decoration: underline;
  }

  a {
     &:hover, &:focus, &:active, &:visited {
      text-decoration: none;
      color: inherit;
    }
  }

  html {
    background: #000;
    color: white; 
    font-size: 20px;
    max-width:100vw;
    overflow-x: hidden;

    @media screen and (max-width: 1200px) {
      font-size: 18px;
    }
      
    @media screen and (max-width: 900px) {
      font-size: 16px;
    }
  }

  body{
    max-width: 100vw;
    overflow: hidden;
    
    &.modal-open{
      position: fixed;
      /* width: 100vw; */
    }
  }
  

  ::-webkit-scrollbar {
  width: 10px;
    @media screen and (max-width: 900px) {
      width: 0px;
    }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #010606;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e6be03;
  }

`;

export default GlobalStyle;
