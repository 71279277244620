import { useEffect, useState, useContext } from "react";
import {
  AppointmentButton,
  HeaderInner,
  HeaderLinksWrap,
  HeaderWrap,
  MobileIcon,
} from "./headerStyles";

import HeaderLink from "./styledLink";
import StyledHeaderLogo from "./StyledHeaderLogo";
import { FaBars } from "react-icons/fa";
import { UserContext } from "../context/";
import Link from "next/link";
import { Fade } from "react-reveal";
export const Header = ({ toggle }) => {
  const { isModalOpen } = useContext(UserContext);
  const [scrollNav, setScrollNav] = useState(false);
  const scroller = () => {
    if (window.scrollY > 80) {
      setScrollNav(true);
    } else if (window.scrollY < 50) {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      scroller();
    });
  }, []);

  return (
    <HeaderWrap scrollNav={scrollNav}>
      <HeaderInner>
        <Fade when={!isModalOpen}>
          <StyledHeaderLogo scrollNav={scrollNav} />
        </Fade>
        <Fade>
          <MobileIcon scrollNav={scrollNav}>
            <FaBars onClick={toggle} />
          </MobileIcon>
        </Fade>
        <HeaderLinksWrap>
          <Fade when={!isModalOpen} bottom delay={300}>
            <HeaderLink href="/" name="Home" />
          </Fade>
          <Fade when={!isModalOpen} bottom delay={250}>
            <HeaderLink href="/practice-area" name="Practice Area" />
          </Fade>
          <Fade when={!isModalOpen} bottom delay={200}>
            <HeaderLink href="/services" name="Services" />
          </Fade>
          <Fade when={!isModalOpen} bottom delay={150}>
            <HeaderLink href="/careers" name="Careers" />
          </Fade>
          <Fade when={!isModalOpen} bottom delay={100}>
            <HeaderLink href="/blogs" name="Blogs" />
          </Fade>
        </HeaderLinksWrap>
        <Fade when={!isModalOpen} bottom delay={100}>
          <Link href="/contact" passHref>
            <AppointmentButton scrollNav={scrollNav}>
              CONTACT US
            </AppointmentButton>
          </Link>
        </Fade>
      </HeaderInner>
    </HeaderWrap>
  );
};
