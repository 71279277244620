import { useState, createContext } from "react";

export const UserContext = createContext();

const ContextWrapper = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const toggleOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <UserContext.Provider value={{ isModalOpen, toggleOpen }}>
      {children}
    </UserContext.Provider>
  );
};

export default ContextWrapper;
