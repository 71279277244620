import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";
import logo from "../images/va-logo.png";

export const HeaderLogoWrap = styled.div`
  width: ${({ scrollNav, footer }) =>
    scrollNav || footer ? "12rem" : "15rem"};
  margin-top: ${({ scrollNav, footer }) =>
    scrollNav ? "2px" : footer ? "-10px" : "0"};
  overflow: hidden;
  max-width: 500px;
  transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) all;
  @media screen and (max-width: 1040px) {
    width: 12rem;
  }
`;

export const StyledLink = styled.a``;

const StyledHeaderLogo = ({ scrollNav, footer }) => (
  <Link href="/" passHref>
    <StyledLink>
      <HeaderLogoWrap footer={footer} scrollNav={scrollNav}>
        <Image
          alt="V.A Law Offices Logo"
          layout="responsive"
          src={logo.src}
          height={logo.height}
          width={logo.width}
        />
      </HeaderLogoWrap>
    </StyledLink>
  </Link>
);
export default StyledHeaderLogo;
